import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { WorkflowExecution } from '@classes/workflow/workflow-execution';
import { WorkflowService } from '@services/workflow.service';
import { HttpResponse } from '@angular/common/http';
import { ReferenceMonthService } from '@services/reference-month.service';
import { DateTime } from 'luxon';

@Injectable()
export class WorkflowExecutionsResolver implements Resolve<HttpResponse<WorkflowExecution[]>> {

    constructor(
        private uiSrv: UiService,
        private workflowSrv: WorkflowService,
        private refMonthSrv: ReferenceMonthService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HttpResponse<WorkflowExecution[]>> {
        this.uiSrv.routeLoadingStateChanged.next(true);
        const previousYearRefMonth = DateTime.fromISO(this.refMonthSrv.refMonth).minus({ year: 1 });
        return this.workflowSrv.getList(`referenceMonth>=${previousYearRefMonth}`, '-createdOn', 1, 10)
            .pipe(
                tap(() => {
                    this.uiSrv.routeLoadingStateChanged.next(false);
                })
            );
    }
}
